@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #e6e6e6;
}

/* ::-webkit-scrollbar {
  display: none;
} */
.formFooter {
  opacity: 0 !important;
}
